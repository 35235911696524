import { Button, Stack, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import moment from "moment-jalaali";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import { openModal } from "../../redux/features/modalSlice";
import RequestLeadModal from "../modal/RequestLeadModal";

export interface LeadRequestProps {
    id?: string;
    description?: string;
    source?: string;
    createdAt?: string;
}

const LeadRequest: React.FC<LeadRequestProps> = ({
    id,
    source,
    createdAt,
    description,
}) => {
    const dispatch: any = useAppDispatch();
    const isOpen = useAppSelector((state) => state.modal.isOpen);

    return (
        <Grid
            sx={{
                backgroundColor: "#EEEEEE",
                margin: "15px 0",
                padding: "20px 15px",
            }}
            container
            alignItems="center"
            spacing={2}
        >
            <Grid xs={2}>
                <Stack direction="row">
                    <Typography
                        sx={{
                            minWidth: "100%",
                            textAlign: "center",
                            backgroundColor: "#007E6F",
                            color: "#ffffff",
                            width: "90px",
                            padding: "9px 0",
                            borderRadius: "7px",
                            fontSize: "11px",
                        }}
                    >
                        وضعیت درخواست: پیگیری شده
                    </Typography>
                </Stack>
            </Grid>

            <Grid xs={3}>
                <Stack
                    justifyContent="center"
                    alignItems="center"
                    direction="row"
                    gap="8px"
                >
                    <Typography
                        sx={{
                            color: "#625F5F",
                            fontWeight: "300",
                            fontSize: "12px",
                        }}
                    >
                        تاریخ ایجاد درخواست:
                    </Typography>

                    <Typography>
                        {moment(createdAt, "YYYY/MM/DD")
                            .locale("fa")
                            .format("YYYY/MM/DD")}
                    </Typography>
                </Stack>
            </Grid>

            <Grid xs={3}>
                <Stack
                    justifyContent="center"
                    alignItems="center"
                    direction="row"
                    gap="8px"
                >
                    <Typography
                        sx={{
                            color: "#625F5F",
                            fontWeight: "300",
                            fontSize: "12px",
                        }}
                    >
                        ساعت ایجاد درخواست:
                    </Typography>

                    <Typography>
                        {moment(createdAt, "HH:mm:ss")
                            .locale("fa")
                            .format("HH:mm:ss")}
                    </Typography>
                </Stack>
            </Grid>

            <Grid xs={2}>
                <Stack
                    justifyContent="center"
                    alignItems="center"
                    direction="row"
                    gap="8px"
                >
                    <Typography
                        sx={{
                            color: "#625F5F",
                            fontWeight: "300",
                            fontSize: "12px",
                        }}
                    >
                        منبع ایجاد:
                    </Typography>
                    <Typography>{source}</Typography>
                </Stack>
            </Grid>

            <Grid xs={2}>
                <Stack alignItems="flex-end" direction="row">
                    <Button
                        onClick={() => dispatch(openModal())}
                        sx={{ width: "100%" }}
                        variant="outlined"
                        color="primary"
                    >
                        جزئیات درخواست
                    </Button>
                </Stack>
            </Grid>

            <RequestLeadModal
                id={id}
                createdAt={createdAt}
                description={description}
            />
        </Grid>
    );
};

export default LeadRequest;
