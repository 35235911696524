import { Button } from "@mui/material";
import { styled } from "@mui/system";

export const DropzoneContainer = styled("div")({
    width: "100%",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "space-between",
    gap: "20px",
    cursor: "pointer",
    justifyContent: "center",
    border: "1px solid #004163",
    backgroundColor: "#ffffff",
    padding: "1.5rem",
    borderRadius: "5px",
});

export const DropzoneText = styled("div")({
    fontWeight: 600,
});

export const DropzoneSvg = styled("svg")(`
    height: 80px;
    fill: #00293f;
`);

export const DropzoneDanger = styled("span")(`
    color: #ff3636;
    font-size: 12px;
    font-weight: 300;
`);

export const FileUploadLabel = styled("label")({
    width: "100%",
    cursor: "pointer",
    backgroundColor: "#ddd",
    padding: "30px 70px",
    borderRadius: "40px",
    border: "2px dashed rgb(82, 82, 82)",
    boxShadow: "0px 0px 200px -50px rgba(0, 0, 0, 0.719)",
    "& input": {
        display: "none",
    },
    "& svg": {
        height: "50px",
        fill: "rgb(82, 82, 82)",
        marginBottom: "20px",
    },
});

export const FileUploadDesign = styled("div")({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: "5px",
});

export const BrowseButton = styled("span")({
    backgroundColor: "rgb(82, 82, 82)",
    padding: "5px 15px",
    borderRadius: "10px",
    color: "white",
    transition: "all 0.3s",
    "&:hover": {
        backgroundColor: "rgb(14, 14, 14)",
    },
});

export const FileUploadForm = styled("div")({
    width: "100%",
    height: "fit-content",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
});
