import { IoGitMergeOutline } from "react-icons/io5";
import { RxDashboard } from "react-icons/rx";
import { TbMessage2 } from "react-icons/tb";
import { FiPhoneCall, FiHelpCircle } from "react-icons/fi";
import { IoSettingsOutline } from "react-icons/io5";
import { GrUpdate } from "react-icons/gr";
import { MdOutlineBugReport, MdOutlineMessage } from "react-icons/md";
import { PiSignpostBold } from "react-icons/pi";
import { IconType } from "react-icons";

export type RouteProps = {
    label: string;
    openName: string;
    icon: IconType,
    route: string,
    subRoutes: SubRouteProps[];
}
export type SubRouteProps = {
  label: string;
  route: string;
};

export const routes: RouteProps[] = [
    {
        label: 'داشبورد اصلی',
        icon: RxDashboard ,
        openName: 'dashboard',
        route: '/',
        subRoutes: []
    },
    {
        label: 'سرنخ های فروش',
        icon: IoGitMergeOutline,
        openName: 'leads',
        route: '/#',
        subRoutes: [
            {
                label: 'داشبورد',
                route: '/leads-dashboard'
            },
            {
                label: 'سرنخ های فروش',
                route: '/leads'
            },
            {
                label: 'ایجاد سرنخ از فایل',
                route: '/create-lead'
            },
            {
                label: 'دسته بندی سرنخ ها',
                route: '/leads-category'
            },
        ]
    },
    {
        label: 'دفترچه تلفن',
        icon: IoGitMergeOutline,
        openName: 'phoneBook',
        route: '/#',
        subRoutes: [
            {
                label: 'افزودن شماره از فایل',
                route: '/manage-persons'
            },
            {
                label: 'افزودن سریع شماره',
                route: '/manage-persons'
            },
            {
                label: 'ایجاد گروه',
                route: '/manage-persons'
            },
            {
                label: 'مدیریت گروه ها',
                route: '/manage-persons'
            },
        ]
    },
    {
        label: 'کمپین های پیامکی',
        icon: TbMessage2,
        openName: 'messages_campaigns',
        route: '/#',
        subRoutes: [
            {
                label: 'ایجاد کمپین',
                route: '/manage-persons'
            },
            {
                label: 'گزارش کمپین ها',
                route: '/manage-persons'
            },
        ]
    },
    {
        label: 'کمپین تلگرامی',
        icon: IoGitMergeOutline,
        openName: 'telegram_campaigns',
        route: '/#',
        subRoutes: [
            {
                label: 'داشبورد',
                route: '/telegram-dashboard'
            },
            {
                label: 'مدیریت کانال ها',
                route: '/manage-channels'
            },
            {
                label: 'کاربران تلگرامی',
                route: '/manage-persons'
            },
        ]
    },
    {
        label: 'کمپین های تماس',
        icon: FiPhoneCall,
        openName: 'phone_campaigns',
        route: '/#',
        subRoutes: [
            {
                label: 'ایجاد کمپین',
                route: '/manage-persons'
            },
            {
                label: 'پیام های دریافتی',
                route: '/manage-persons'
            },
            {
                label: 'گزارش کمپین ها',
                route: '/manage-persons'
            },
        ]
    },
    {
        label: 'پیام های دریافتی',
        icon: MdOutlineMessage,
        openName: 'received_messages',
        route: '#',
        subRoutes: []
    },
    {
        label: 'انتقادات و پیشنهادات',
        icon: PiSignpostBold,
        openName: 'suggestions',
        route: '#',
        subRoutes: []
    },
    {
        label: 'گزارش خطا',
        icon: MdOutlineBugReport,
        openName: 'report',
        route: '#',
        subRoutes: []
    },
    {
        label: 'به روزرسانی ها',
        icon: GrUpdate,
        route: '#',
        openName: 'upgrades',
        subRoutes: []
    },
    {
        label: 'راهنمای سامانه',
        icon: FiHelpCircle,
        openName: 'help',
        route: '#',
        subRoutes: []
    },
    {
        label: 'تنظیمات',
        icon: IoSettingsOutline,
        openName: 'setting',
        route: '#',
        subRoutes: []
    },
];
