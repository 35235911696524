import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { Box, Card, FormHelperText, Stack, Typography } from "@mui/material";
import { LoginSubmitButton } from "./styles";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import { useNavigate } from "react-router-dom";
import axios from "../../axiosInstance";
import {
    AuthenticatedUser,
    addTokenToUser,
} from "../../redux/features/userSlice";
import { MuiOtpInput } from "mui-one-time-password-input";
import { useEffect, useState } from "react";
import CustomProgress from "../../components/progress/CustomProgress";
import useLocalStorage from "../../hooks/useLocalStorage";

interface IFormInputs {
    code: string;
}

const Otp = () => {
    const [progress, setProgress] = useState(0);
    const [otpMinuteCounter, setOtpMinuteCounter] = useState(1);
    const [otpSecondCounter, setOtpSecondCounter] = useState(0);
    const dispatch = useAppDispatch();
    const phoneNumber = useAppSelector((state) => state.user.user.phoneNumber);
    const user = useAppSelector((state) => state.user.user);

    const [value, updateValue] = useLocalStorage<AuthenticatedUser>("token", {
        token: "",
    });

    useEffect(() => {
        const timer = setInterval(() => {

            if (otpSecondCounter > 0) {
                setOtpSecondCounter(prev => prev - 1);
            }

            if (otpSecondCounter === 0) {
                if (otpMinuteCounter === 0) {
                    clearInterval(timer)
                } else {
                    setOtpSecondCounter(59);
                    setOtpSecondCounter(otpMinuteCounter - 1);
                }
            }

        }, 1000);

        return () => {
            clearInterval(timer);
        }
    }, [otpSecondCounter]);

    const navigate = useNavigate();

    const {
        control,
        handleSubmit,
        formState: { isSubmitting },
    } = useForm<IFormInputs>({
        defaultValues: {
            code: "",
        },
    });

    const onSubmit: SubmitHandler<IFormInputs> = async (data) => {
        try {
            const res = await axios.post(
                "/auth/login/check",
                {
                    ...data,
                    phoneNumber,
                },
                {
                    onUploadProgress: (progressEvent: any) => {
                        const percentCompleted = Math.round(
                            (progressEvent.loaded * 100) / progressEvent.total
                        );
                        setProgress(percentCompleted);
                    },
                }
            );
            if (res.status === 200) {
                dispatch(
                    addTokenToUser({
                        ...user,
                        user: {
                            firstName: res?.data?.data?.user?.firstName,
                            lastName: res?.data?.data?.user?.lastName,
                        },
                        isLoggedIn: true,
                        token: res?.data?.data?.token,
                    })
                );
                // console.log(res.data);
                updateValue({
                    token: res?.data?.data?.token
                })
                // setCookies('accToken', res?.data?.data?.token);
                // console.log(res?.data?.data?.token);
                navigate("/");
            } else {
                throw new Error("کد اعتبارسنجی معتبر نیست.");
            }
        } catch (error: any) {
            console.log(error.message);
        }
    };

    if (!phoneNumber) {
        navigate("/login");
    }

    return (
        <Card sx={ { width: "460px", padding: "30px" } }>
            <Stack gap="30px">
                <Stack alignItems="center">
                    <img
                        style={ { width: "100px", height: "100px" } }
                        src={ `${
                            process.env.PUBLIC_URL + "/img/pishgaman_logo.svg"
                        }` }
                        alt="pishgaman_logo"
                    />
                </Stack>

                <form onSubmit={ handleSubmit(onSubmit) }>
                    <Stack gap="30px">
                        <Stack
                            sx={ { width: "100%" } }
                            alignItems="flex-start"
                            gap="20px"
                        >
                            <Stack
                                sx={ { width: "100%" } }
                                direction="row"
                                justifyContent="space-between"
                            >
                                <Typography
                                    textAlign="center"
                                    fontSize="1.2rem"
                                    sx={ { color: "#333333" } }
                                >
                                    کد پیامکی را وارد کنید
                                </Typography>
                                <Typography
                                    textAlign="center"
                                    fontSize="1rem"
                                    sx={ { color: "#598094" } }
                                >
                                    2:00
                                </Typography>
                            </Stack>

                            { isSubmitting ? (
                                <Stack sx={ { width: "100%" } }>
                                    <CustomProgress
                                        value={ progress }
                                        label="در حال انتقال به داشبورد ..."
                                    />
                                </Stack>
                            ) : (
                                <Controller
                                    control={ control }
                                    rules={ {
                                        validate: (value) => value.length === 5,
                                    } }
                                    render={ ({ field, fieldState }) => (
                                        <Box>
                                            <MuiOtpInput
                                                autoFocus
                                                sx={ {
                                                    gap: 1,
                                                    direction: "ltr",
                                                } }
                                                { ...field }
                                                length={ 5 }
                                            />
                                            { fieldState.invalid ? (
                                                <FormHelperText error>
                                                    OTP invalid
                                                </FormHelperText>
                                            ) : null }
                                        </Box>
                                    ) }
                                    name="code"
                                />
                            ) }
                        </Stack>

                        <Stack>
                            <LoginSubmitButton
                                type="submit"
                                variant="contained"
                                color="primary"
                            >
                                ورود به داشبورد
                            </LoginSubmitButton>
                        </Stack>
                    </Stack>
                </form>
            </Stack>
        </Card>
    );
};

export default Otp;
