import { Stack } from "@mui/material";
import { styled } from "@mui/system";

export const SideBarLayout = styled("div")({
  backgroundColor: "#ffffff",
  borderRadius: "4px",
  boxShadow: "0px 0px 7px 0px rgba(0, 0, 0, 0.1)",
});

export const MainLayout = styled(Stack)();
