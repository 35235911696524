import { useState } from "react";

type StoredValue<T> = T | null;

// Define the hook
function useLocalStorage<T>(
    key: string,
    initialValue?: T
): [StoredValue<T>, (value: T) => void] {
    // Get the stored value from local storage
    const storedValue: StoredValue<T> =
        JSON.parse(localStorage.getItem(key) || "null") || initialValue;

    // Create state to hold the current value
    const [value, setValue] = useState<StoredValue<T>>(storedValue);

    // Define a function to update the value in local storage and the state
    const updateValue = (newValue: T) => {
        // Update the state
        setValue(newValue);

        // Update local storage
        localStorage.setItem(key, JSON.stringify(newValue));
    };

    return [value, updateValue];
}

export default useLocalStorage;
