import { configureStore } from "@reduxjs/toolkit";
import { UserSlice } from "./features/userSlice";
import { TypedUseSelectorHook, useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { LeadSlice } from "./features/leadSlice";
import { ModalSlice } from "./features/modalSlice";

export const store = configureStore({
    reducer: {
        user: UserSlice.reducer,
        lead: LeadSlice.reducer,
        modal: ModalSlice.reducer,
    },
});

export type RootState = ReturnType<typeof store.getState>;
export const useAppDispatch: () => typeof store.dispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
