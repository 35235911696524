import Grid from "@mui/material/Unstable_Grid2";
import SingleLoadPhoneForm from "../../components/single-load-phone/SingleLoadPhoneForm";
import GroupLoadPhoneForm from "../../components/group-load-phone/GroupLoadPhoneForm";
import { Stack, Tab } from "@mui/material";
import CustomTable from "../../components/table/CustomTable";
import CustomPagination from "../../components/pagination/CustomPagination";
import { useState } from "react";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { LoadNumberHeaderData, LoadNumberTableData } from "../../tableData";

type LoadPhoneType = "single" | "group";

const LoadNumber = () => {
    const [loadType, setLoadType] = useState<LoadPhoneType>("single");

    const handleChange = (
        event: React.SyntheticEvent,
        newValue: LoadPhoneType
    ) => {
        setLoadType(newValue);
    };

    return (
        <Grid container spacing={3}>
            <Grid xs={7}>
                <Stack gap="20px">
                    <CustomTable
                        tableHeaderData={LoadNumberHeaderData}
                        tableBodyData={LoadNumberTableData}
                    />
                    <CustomPagination />
                </Stack>
            </Grid>
            <Grid xs={5}>
                <TabContext value={loadType}>
                    <Stack direction={"row"} justifyContent={"space-between"}>
                        <TabList 
                            sx={{ justifyContent: "center" }}
                            indicatorColor="secondary"
                            textColor="secondary"
                            onChange={handleChange}
                        >
                            <Tab label="بارگذاری فردی شماره" value="single" />
                            <Tab label="بارگذاری گروهی شماره" value="group" />
                        </TabList>
                    </Stack>
                    <TabPanel sx={{ padding: "20px 0" }} value="single">
                        <SingleLoadPhoneForm />
                    </TabPanel>
                    <TabPanel sx={{ padding: "20px 0" }} value="group">
                        <GroupLoadPhoneForm />
                    </TabPanel>
                </TabContext>
            </Grid>
        </Grid>
    );
};

export default LoadNumber;
