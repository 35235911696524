import { Stack, Typography } from "@mui/material";
import { Loader } from "./styles";

const CustomLoader = () => {
    return (
        <>
            <Stack alignItems="center" gap="20px">
                <Typography fontSize="18px">در حال بارگذاری</Typography>
                <Loader>
                    <div className="circle"></div>
                    <div className="circle"></div>
                    <div className="circle"></div>
                    <div className="circle"></div>
                </Loader>
            </Stack>
        </>
    );
};

export default CustomLoader;
