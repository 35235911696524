import { Box, Button, Card, Stack, Tab, TextField } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { Link, useParams } from "react-router-dom";
import Input from "../../components/input/Input";
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";
import LeadRequest from "../../components/lead-request/LeadRequest";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { useState } from "react";
import useLocalStorage from "../../hooks/useLocalStorage";
import { AuthenticatedUser } from "../../redux/features/userSlice";
import { TextArea } from "./styles";
import { InputLabel } from "../../components/input/styles";
import useLeadQuery from "../../hooks/useLeadQuery";

const Lead = () => {
    const { leadId } = useParams();
    const [tabValue, setTabValue] = useState("lead-detail");
    const [value, updateValue] = useLocalStorage<AuthenticatedUser>("token");
    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setTabValue(newValue);
    };

    const { leadData } = useLeadQuery({
        id: leadId
    })

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<FieldValues>();

    const onSubmit: SubmitHandler<FieldValues> = async (data) => {
        // await axios.post("https://api.pttfco.com/api/v1/channels/create", {
        //     ...data,
        //     id: leadId,
        // });
        console.log({
            ...data,
            id: leadId,
        });
    };

    return (
        <Stack>
            <TabContext value={ tabValue }>
                <Box sx={ { padding: "10px 0" } }>
                    <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between"
                    >
                        <TabList
                            sx={ {
                                "& .MuiTabs-flexContainer": {
                                    padding: "10px 0 10px 20px",
                                },
                                "& .Mui-selected": {
                                    backgroundColor: "#007E6F",
                                    color: "#ffffff !important",
                                },
                                "& .MuiTabs-indicator": {
                                    display: "none",
                                },
                            } }
                            onChange={ handleChange }
                            aria-label="lab API tabs example"
                        >
                            <Tab
                                sx={ {
                                    filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))",
                                    backgroundColor: "#ffffff",
                                    marginRight: "15px",
                                    borderRadius: "3.796px",
                                    color: "#007E6F",
                                } }
                                label="جزئیات سرنخ"
                                value="lead-detail"
                            />

                            <Tab
                                sx={ {
                                    filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))",
                                    backgroundColor: "#ffffff",
                                    marginRight: "15px",
                                    borderRadius: "3.796px",
                                    color: "#007E6F",
                                } }
                                label="در خواست ها"
                                value="lead-request"
                            />

                            <Tab
                                sx={ {
                                    filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))",
                                    backgroundColor: "#ffffff",
                                    marginRight: "15px",
                                    borderRadius: "3.796px",
                                    color: "#007E6F",
                                } }
                                label="اطلاعات بازاریابی"
                                value="marketing-information"
                            />
                        </TabList>

                        <Link to={ "/leads" }>
                            <Button variant="outlined" color="error">
                                بازگشت به سرنخ ها
                            </Button>
                        </Link>
                    </Stack>
                </Box>

                <TabPanel sx={ { padding: "0 15px 0 0" } } value="lead-detail">
                    <Card sx={ { padding: "67px 130px" } }>
                        <form onSubmit={ onSubmit }>
                            <Grid container spacing={ 4 }>
                                <Grid xs={ 6 }>
                                    <Input
                                        value={ leadData?.fullName }
                                        id="fullName"
                                        label="نام و نام خانوادگی"
                                        register={ register }
                                        errors={ errors }
                                    />
                                </Grid>

                                <Grid xs={ 6 }>
                                    <Input
                                        disabled={ true }
                                        value={ leadData?.phoneNumber }
                                        id="phoneNumber"
                                        label="شماره موبایل"
                                        register={ register }
                                        errors={ errors }
                                    />
                                </Grid>

                                <Grid xs={ 6 }>
                                    <Input
                                        value={
                                            leadData?.canSendSMS
                                                ? "فعال"
                                                : "غیر فعال"
                                        }
                                        id="status"
                                        label="وضعیت سرنخ"
                                        register={ register }
                                        errors={ errors }
                                    />
                                </Grid>

                                <Grid xs={ 6 }>
                                    <Input
                                        value={ leadData?.email }
                                        id="email"
                                        label="آدرس ایمیل"
                                        register={ register }
                                        errors={ errors }
                                    />
                                </Grid>

                                {/* <Grid xs={6}>
                                    <GradesSelect
                                        placeHolder="مقطع تحصیلی"
                                        names={[
                                            "دیپلم",
                                            "کاردانی",
                                            "کارشناسی",
                                            "کارشناسی ارشد",
                                            "دکتری",
                                        ]}
                                    />
                                </Grid> */ }

                                {/* <Grid xs={6}>
                                    <GradesSelect
                                        placeHolder="مرحله"
                                        names={[""]}
                                    />
                                </Grid> */ }

                                <Grid xs={ 12 }>
                                    <InputLabel>توضیحات</InputLabel>
                                    <TextArea
                                        { ...register("description", {
                                            required: true,
                                        }) }
                                        value={ leadData?.description }
                                    />
                                </Grid>

                                <Grid xs={ 12 }>
                                    <Stack alignItems="center">
                                        <Button type="submit" variant="contained" color="primary">ویرایش</Button>
                                    </Stack>
                                </Grid>
                            </Grid>
                        </form>
                    </Card>
                </TabPanel>

                <TabPanel sx={ { padding: "0 15px 0 0" } } value="lead-request">
                    <Card sx={ { padding: "30px", margin: "30px 0" } }>
                        { leadData?.leadRequest.map((request: any) => (
                            <LeadRequest
                                key={ request?.id }
                                id={ request?.id }
                                source={ request?.source }
                                createdAt={ request?.createdAt }
                                description={ request?.description }
                            />
                        )) }
                    </Card>
                </TabPanel>

                <TabPanel
                    sx={ { padding: "0 15px 0 0" } }
                    value="marketing-information"
                ></TabPanel>
            </TabContext>
        </Stack>
    );
};

export default Lead;
