
const LeadsCategory = () => {
  return (
    <div>
      LeadsCategory
    </div>
  )
}

export default LeadsCategory
