import { Stack, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { Outlet } from "react-router";

const LoginLayout = () => {
    return (
        <Grid sx={{ height: "100vh", overflowY: 'hidden' }} container>
            <Grid xs={6}>
                <img
                    src={`${
                        process.env.PUBLIC_URL + "/img/login_background.svg"
                    }`}
                    alt="login_background"
                />
            </Grid>

            <Grid xs={6}>
                <Stack
                    gap="35px"
                    alignItems="center"
                    justifyContent="center"
                    sx={{ height: "100vh" }}
                >
                    <Typography
                        textAlign="center"
                        fontSize="1.3125rem"
                        sx={{ color: "rgba(51, 51, 51, 0.40)" }}
                    >
                        پیشگامان توسعه تجارت فردا
                    </Typography>

                    <Outlet />
                </Stack>
            </Grid>
        </Grid>
    );
};

export default LoginLayout;
