import { CardContent } from "@mui/material";
import { CompaginBoxTitle, CompaginBoxTitleText, CompaginBoxTitleNumber, CardBox } from "./styles";
import { IconType } from "react-icons";

interface CompaginBoxProps {
    label: string;
    Icon: IconType;
    compaginNumber: number;
    status?: string;
}

const CompaginBox: React.FC<CompaginBoxProps> = ({ label, compaginNumber, status, Icon }) => {


    return (
            <CardBox status={status}>
                <CompaginBoxTitle>
                    <Icon size="20px" />
                    <CompaginBoxTitleText>{label}</CompaginBoxTitleText>
                </CompaginBoxTitle>
                <CardContent sx={{ display: 'flex', justifyContent: "flex-end" }}>
                    <CompaginBoxTitleNumber>{compaginNumber}</CompaginBoxTitleNumber>
                </CardContent>
            </CardBox>
    );
};

export default CompaginBox;
