import { Typography } from "@mui/material";
import { InputContainer, InputLabel, TextInput } from "./styles";
import { FieldErrors, FieldValues, UseFormRegister } from "react-hook-form";

interface InputProps {
    label?: string;
    value?: string;
    placeHolder?: string;
    id: string;
    required?: boolean;
    register: UseFormRegister<FieldValues>;
    errors: FieldErrors;
    disabled?: boolean;
}

const Input: React.FC<InputProps> = ({
    label,
    value,
    placeHolder,
    id,
    required,
    register,
    errors,
    disabled,
}) => {
    return (
        <InputContainer>
            <InputLabel>{label}</InputLabel>
            <TextInput
                value={value}
                placeholder={placeHolder}
                id={id}
                disabled={disabled}
                {...register(id, { required })}
            />
            {errors[id] && (
                <Typography color="error" sx={{ margin: '10px 0' }} variant="subtitle1" component="span">
                    {errors[id]?.message?.toString()}
                </Typography>
            )}
        </InputContainer>
    );
};

export default Input;
