import styled from "@emotion/styled";
import { Grid, Stack } from "@mui/material";

export const DailyReportBox = styled(Stack)`
    box-shadow: none;
    border: 1px solid #00293f;
    padding: 20px 7px;
    border-radius: 6px;
    background-color: #ffffff;
    gap: 15px;
`;

export const DailyReportTitle = styled("span")`
    color: #007bbb;
    font-size: 20px;
    font-weight: 500;
`;

export const DailyReportItems = styled(Grid)`
    margin: 18px 0;
`;

export const DailyReportItem = styled(Grid)<{ textColor?: string }>`
    font-weight: 500;
    margin: 10px 0;
    color: ${(props) => props.textColor || "#00293f"};
`;
