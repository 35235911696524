import {
    Menu,
    MenuItem,
    Sidebar,
    SubMenu,
    menuClasses,
} from "react-pro-sidebar";
import { RouteProps } from "../../routes";
import { Link, useLocation } from "react-router-dom";
import { Stack } from "@mui/material";
import { useState } from "react";


const CustomSidebar = ({ routes }: { routes: RouteProps[] }) => {
    const [open, setOpen] = useState<string | undefined>("/");

    const handleOpenSubMenu = (key: string) => {
        if (open === key) {
            setOpen(undefined);
        } else {
            setOpen(key);
        }
    };

    const { pathname } = useLocation();

    return (
        <>
            <Sidebar transitionDuration={200} rtl width="100%" backgroundColor="#fff">
                <Menu
                    rootStyles={{
                        ["." + menuClasses.button]: {
                            margin: ".5rem",
                            transition: "all 250ms",
                            "&:hover": {
                                color: "#ffffff",
                                backgroundColor: "#007e6f",
                                borderRadius: "4px",
                            },
                        },
                        ["." + menuClasses.subMenuContent]: {
                            backgroundColor: "transparent",
                            margin: "0 1rem",
                            borderRadius: "4px",
                        },
                        ["." + menuClasses.menuItemRoot + ' a']: {
                            padding: "0 1rem",
                        },
                    }}
                    menuItemStyles={{
                        button: ({ level, active, disabled }) => {
                            // only apply styles on first level elements of the tree
                            if (level === 0)
                                return {
                                    color: active ? "#ffffff" : "#00293f",
                                    backgroundColor: active
                                        ? "#007e6f"
                                        : undefined,
                                    margin: active ? ".6rem 1rem" : 0,
                                    borderRadius: active ? "5px" : "0",
                                };
                        },
                    }}
                >
                    {routes.map((route) =>
                        route.subRoutes.length > 0 ? (
                            <SubMenu
                                onClick={() => handleOpenSubMenu(route.openName)}
                                open={open === route.openName}
                                icon={<route.icon />}
                                label={route.label}
                            >
                                {route.subRoutes.map((subRoute) => (
                                    <MenuItem
                                        component={<Link to={subRoute.route} />}
                                    >
                                        <Stack direction="row" gap="7px">
                                            <span>_</span>
                                            {subRoute.label}
                                        </Stack>
                                    </MenuItem>
                                ))}
                            </SubMenu>
                        ) : (
                            <MenuItem
                                active={route.route === pathname}
                                icon={<route.icon />}
                                component={<Link to={route.route} />}
                            >
                                {route.label}
                            </MenuItem>
                        )
                    )}
                </Menu>
            </Sidebar>
        </>
    );
};

export default CustomSidebar;
