import { FieldValues, SubmitHandler, useForm } from "react-hook-form";
import { Card, Stack, Typography } from "@mui/material";
import { LoginInput, LoginButton } from "./styles";
import axios from "../../axiosInstance";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import { addPhoneNumberToUser } from "../../redux/features/userSlice";
import { useNavigate } from "react-router-dom";
import CustomProgress from "../../components/progress/CustomProgress";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { LoginValidation } from "../../utils/validation/LoginValidation";

const Login = () => {
    const [progress, setProgress] = useState(0);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const user = useAppSelector((state) => state.user.user);

    const {
        register,
        handleSubmit,
        formState: { isSubmitting, errors },
    } = useForm<FieldValues>({
        resolver: yupResolver(LoginValidation),
        mode: "onBlur",
        defaultValues: {
            phoneNumber: "",
        },
    });

    const onSubmit: SubmitHandler<FieldValues> = async (data) => {
        try {
            const res = await axios.post(
                "/auth/login",
                data,
                {
                    onUploadProgress: (progressEvent: any) => {
                        const percentCompleted = Math.round(
                            (progressEvent.loaded * 100) / progressEvent.total
                        );
                        setProgress(percentCompleted);
                    },
                }
            );
            if (res.status === 200) {
                dispatch(addPhoneNumberToUser(data));
                navigate("/login/otp");
            } else {
                throw new Error("شماره موبایل معتبر نمی باشد.");
            }
        } catch (error: any) {
            console.log(error.message);
        }
    };

    return (
        <Card sx={{ width: "460px", padding: "30px" }}>
            <Stack gap="30px">
                <Stack alignItems="center">
                    <img
                        style={{ width: "100px", height: "100px" }}
                        src={`${
                            process.env.PUBLIC_URL + "/img/pishgaman_logo.svg"
                        }`}
                        alt="pishgaman_logo"
                    />
                </Stack>

                <form onSubmit={handleSubmit(onSubmit)}>
                    <Stack
                        sx={{ width: "100%" }}
                        alignItems="flex-start"
                        gap="15px"
                    >
                        {isSubmitting ? (
                            <Stack sx={{ width: "100%" }}>
                                <CustomProgress
                                    value={progress}
                                    label="در حال ارسال اطلاعات به سمت سرور."
                                />
                            </Stack>
                        ) : (
                            <Stack sx={{ width: "100%" }}>
                                <Stack direction="row">
                                    <LoginButton
                                        type="submit"
                                        variant="contained"
                                        color="secondary"
                                    >
                                        ارسال کد تایید
                                    </LoginButton>

                                    <LoginInput
                                        {...register("phoneNumber", {
                                            required: true,
                                        })}
                                        placeholder="091212345678"
                                    />
                                </Stack>
                                <Typography
                                    color="error"
                                    variant="subtitle1"
                                    component="span"
                                    sx={{ margin: '10px 0' }}
                                >
                                    {errors["phoneNumber"]?.message?.toString()}
                                </Typography>
                            </Stack>
                        )}
                    </Stack>
                </form>
            </Stack>
        </Card>
    );
};

export default Login;
