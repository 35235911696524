// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./fonts/IRANYekanXVF.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./fonts/IRANYekanXVF.woff2", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
    font-family: yekan;
    font-style: normal;
    font-weight: normal;
    src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format("woff"),
        url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format("woff2");
}

*,
*::before,
*::after {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

html {
    font-size: 14px;
}

body {
    font-size: 1rem;
    background: #f7f7f7;
    font-family: yekan;
    line-height: 1.8rem;
    font-feature-settings: "ss02";
    font-variation-settings: "wght"400 "dots"4;
}`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,kBAAkB;IAClB,mBAAmB;IACnB;+DACqD;AACzD;;AAEA;;;IAGI,UAAU;IACV,SAAS;IACT,sBAAsB;AAC1B;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,eAAe;IACf,mBAAmB;IACnB,kBAAkB;IAClB,mBAAmB;IAGnB,6BAA6B;IAC7B,0CAA0C;AAC9C","sourcesContent":["@font-face {\n    font-family: yekan;\n    font-style: normal;\n    font-weight: normal;\n    src: url(\"./fonts/IRANYekanXVF.woff\") format(\"woff\"),\n        url(\"./fonts/IRANYekanXVF.woff2\") format(\"woff2\");\n}\n\n*,\n*::before,\n*::after {\n    padding: 0;\n    margin: 0;\n    box-sizing: border-box;\n}\n\nhtml {\n    font-size: 14px;\n}\n\nbody {\n    font-size: 1rem;\n    background: #f7f7f7;\n    font-family: yekan;\n    line-height: 1.8rem;\n    -moz-font-feature-settings: \"ss02\";\n    -webkit-font-feature-settings: \"ss02\";\n    font-feature-settings: \"ss02\";\n    font-variation-settings: \"wght\"400 \"dots\"4;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
