import { Button, Card, Container, Stack, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import axios from "../../../axiosInstance";
import { IoIosHelpCircleOutline } from "react-icons/io";
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import LeadDropdzone from "../../../components/dropzone/LeadDropdzone";
import useLocalStorage from "../../../hooks/useLocalStorage";
import { AuthenticatedUser } from "../../../redux/features/userSlice";

const CreateLead = () => {
    const [jsonData, setJsonData] = useState<any[]>([]);
    const [value, updateValue] = useLocalStorage<AuthenticatedUser>("token");

    const handleFileUpload = (data: any[]) => {
        setJsonData(data);
    };

    useEffect(() => {
        console.log(jsonData);
    }, [jsonData]);

    const {
        handleSubmit,
    } = useForm();

    const onSubmit: SubmitHandler<FieldValues> = async () => {
        await axios.post(
            "/leads/create/many",
            {
                data: jsonData,
            },
            {
                headers: {
                    Authorization: `Bearer ${value?.token}`,
                },
            }
        );
    };

    return (
        <Stack gap="20px">
            <Grid container spacing={4}>
                <Grid xs={6}>
                    <Typography
                        sx={{
                            margin: "0 7px",
                            filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))",
                            display: "flex",
                            gap: "5px",
                            minWidth: "135px",
                            justifyContent: "center",
                            textAlign: "center",
                            backgroundColor: "#F1F1F1",
                            color: "#007E6F",
                            width: "90px",
                            padding: "9px 0",
                            borderRadius: "7px",
                            fontSize: "11px",
                        }}
                    >
                        <IoIosHelpCircleOutline fontSize="20px" />
                        <span style={{ marginTop: "2px" }}>
                            راهنمای بارگذاری
                        </span>
                    </Typography>
                </Grid>

                <Grid xs={6}>
                    <Typography
                        sx={{
                            filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))",
                            display: "flex",
                            gap: "5px",
                            minWidth: "135px",
                            justifyContent: "center",
                            textAlign: "center",
                            backgroundColor: "#F1F1F1",
                            color: "#007E6F",
                            width: "90px",
                            padding: "9px 0",
                            borderRadius: "7px",
                            fontSize: "11px",
                        }}
                    >
                        <IoIosHelpCircleOutline fontSize="20px" />
                        <span style={{ marginTop: "2px" }}>
                            راهنمای بارگذاری
                        </span>
                    </Typography>
                </Grid>

                <Grid xs={6} sx={{ margin: "-50px -2px 0 0" }}>
                    <Card>
                        <Container maxWidth={false}>
                            <Stack
                                sx={{
                                    padding: "35px 100px",
                                    position: "relative",
                                }}
                            >
                                <form
                                    style={{ marginTop: "70px 0" }}
                                    onSubmit={handleSubmit(onSubmit)}
                                >
                                    <Stack gap="30px" alignItems="center">
                                        {/* <CustomDropzone onFileUpload={handleFileUpload} /> */}
                                        <LeadDropdzone
                                            id="files"
                                            onFileUpload={handleFileUpload}
                                        />

                                        <Button
                                            sx={{
                                                margin: "0 0 2px 0",
                                                width: "100%",
                                            }}
                                            type="submit"
                                            size="large"
                                            variant="contained"
                                            color="primary"
                                        >
                                            افزودن
                                        </Button>
                                    </Stack>
                                </form>
                            </Stack>
                        </Container>
                    </Card>
                </Grid>

                <Grid xs={6}></Grid>
            </Grid>
        </Stack>
    );
};

export default CreateLead;
