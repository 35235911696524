import {
    Avatar,
    Box,
    IconButton,
    ListItemIcon,
    Menu,
    MenuItem,
    Tooltip,
} from "@mui/material";
import { RxAvatar } from "react-icons/rx";
import { useState } from "react";
import { IconType } from "react-icons";

interface DropdownMenuProps {
    tooltipLabel: string;
    DropdownIcon: IconType;
}

type Anchor = null | HTMLElement;

const DropdownMenu: React.FC<DropdownMenuProps> = ({ DropdownIcon, tooltipLabel }) => {
    const [anchorEl, setAnchorEl] = useState<Anchor>(null);

    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    textAlign: "center",
                }}
            >
                <Tooltip title={tooltipLabel}>
                    <IconButton
                        onClick={handleClick}
                        size="small"
                        sx={{ padding: "1.2rem" }}
                        aria-controls={open ? "account-menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? "true" : undefined}
                    >
                        <DropdownIcon color="#ffffff" fontSize="2rem"/>
                    </IconButton>
                </Tooltip>
            </Box>
            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: "visible",
                        filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                        mt: 1.5,
                        "& .MuiAvatar-root": {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        "&::before": {
                            content: '""',
                            display: "block",
                            position: "absolute",
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: "background.paper",
                            transform: "translateY(-50%) rotate(45deg)",
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
                <MenuItem onClick={handleClose}>
                    <ListItemIcon>
                        <RxAvatar fontSize="25px" />
                    </ListItemIcon>
                    Add another account
                </MenuItem>
            </Menu>
        </>
    );
};

export default DropdownMenu;
