import { Card, CardProps, styled } from "@mui/material";

interface CustomCardProps extends CardProps {
    status?: string;
}

export const CompaginBoxTitle = styled("div")`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    padding: 16px 35px 0 16px;
`;

export const CompaginBoxTitleText = styled("div")`
    font-size: 16px;
    font-weight: 500;
`;

export const CompaginBoxTitleNumber = styled(CompaginBoxTitleText)`
    font-size: 18px;
`;

export const CardBox = styled(Card, {
    // shouldForwardProp: (prop) => prop
})<CustomCardProps>(({ status, theme }) => ({
    position: 'relative',
    borderWidth: "1px",
    borderStyle: "solid",
    borderColor: "transparent",
    boxShadow: `${
        status === "none" ? "none" : "0px 4px 4px 0px rgba(0, 0, 0, 0.25)"
    }`,
    [`& svg`]: {
        padding: '10px',
        borderRadius: '3px',
        position: 'absolute',
        top: '50%',
        right: '-22px',
        zIndex: 9999999,
        transform: 'translateY(-50%)',
        color: '#ffffff',
        backgroundColor: `${theme.palette.primary.main}`,
    }
}));
