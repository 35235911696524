import Papa from "papaparse";
import { ChangeEvent } from "react";
import {
    BrowseButton,
    FileUploadDesign,
    FileUploadForm,
    FileUploadLabel,
} from "./styles";
import { FieldErrors, FieldValues, UseFormRegister } from "react-hook-form";

interface CSVFileInputProps {
    id: string;
    onFileUpload: (jsonData: any[]) => void;
}

const LeadDropdzone: React.FC<CSVFileInputProps> = ({ onFileUpload, id }) => {
    const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];

        if (file) {
            Papa.parse(file, {
                complete: (result) => {
                    onFileUpload(result.data);
                },
                header: true, // Set to true if your CSV has headers
            });
        }
    };

    return (
        <FileUploadForm>
            <FileUploadLabel htmlFor={id} className="file-upload-label">
                <FileUploadDesign className="file-upload-design">
                    <svg viewBox="0 0 640 512" height="1em">
                        <path d="M144 480C64.5 480 0 415.5 0 336c0-62.8 40.2-116.2 96.2-135.9c-.1-2.7-.2-5.4-.2-8.1c0-88.4 71.6-160 160-160c59.3 0 111 32.2 138.7 80.2C409.9 102 428.3 96 448 96c53 0 96 43 96 96c0 12.2-2.3 23.8-6.4 34.6C596 238.4 640 290.1 640 352c0 70.7-57.3 128-128 128H144zm79-217c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l39-39V392c0 13.3 10.7 24 24 24s24-10.7 24-24V257.9l39 39c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-80-80c-9.4-9.4-24.6-9.4-33.9 0l-80 80z"></path>
                    </svg>
                    <p>فایل خود را بکشید</p>
                    <p>یا</p>
                    <BrowseButton className="browse-button">
                        فایل خود را انتخاب کنید
                    </BrowseButton>
                </FileUploadDesign>
                <input id={id} onChange={handleFileChange} accept=".csv" type="file" />
            </FileUploadLabel>
        </FileUploadForm>
    );
};

export default LeadDropdzone;
