import useLocalStorage from "./useLocalStorage";
import { AuthenticatedUser } from "../redux/features/userSlice";
import axios from "../axiosInstance";
import { ISingleLeadRequestStatus } from "../utils/IRequestStatus";
import { useQuery, useSuspenseQuery } from "@tanstack/react-query";

interface UseLeadQuerykey {
    id: string | undefined;
}

const useLeadQuery = (leadKey: UseLeadQuerykey) => {
    const [value, updateValue] = useLocalStorage<AuthenticatedUser>("token");

    const getLead = async (queryKeys: (string | UseLeadQuerykey)[]) => {
        const { id } = queryKeys[1] as UseLeadQuerykey;

        const { data } = await axios.get<ISingleLeadRequestStatus>(`/leads/${id}`, {
            headers: {
                Authorization: `Bearer ${ value?.token }`,
            },
        });
        return data;
    };

    const { data: leadData } = useQuery({
        queryKey: ["lead", leadKey],
        queryFn: async ({ queryKey }) => getLead(queryKey),
        select: (result) => {
            return result?.data;
        }
    });

    return { leadData };
}

export default useLeadQuery;