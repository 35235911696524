import { Button, styled } from "@mui/material";
import { TextInput } from "../../components/input/styles";
// import { MuiOtpInput } from "mui-one-time-password-input";


export const LoginInput = styled(TextInput)({
    direction: 'ltr',
    [`& fieldset`]: {
        borderRadius: 0,
        borderTopLeftRadius: "5px",
        borderBottomLeftRadius: "5px",
    },
});

export const LoginButton = styled(Button)({
    width: "130px",
    borderRadius: 0,
    borderTopRightRadius: "4px",
    borderBottomRightRadius: "4px",
});

export const LoginSubmitButton = styled(Button)({
    width: "100%",
    fontSize: '16px',
});

// export const CustomMuiOtpInput = styled(MuiOtpInput)({
//     direction: 'ltr',
// });
