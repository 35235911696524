import { useEffect } from "react";
import { useAppSelector } from "../../redux/store";

const MainDashboard = () => {
    const user = useAppSelector((state) => state.user.user);

    useEffect(() => {
        console.log(user);
    }, [user]);

    return <div>خانه</div>;
};

export default MainDashboard;
