import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { FieldValues } from "react-hook-form";

interface UserProfile {
    firstName: string;
    lastName: string;
}

export interface AuthenticatedUser {
    user?: UserProfile;
    phoneNumber?: string;
    isLoggedIn?: boolean;
    token: string;
}

interface UserState {
    user: AuthenticatedUser;
}

export const initialState: UserState = {
    user: {
        user: {
            firstName: "",
            lastName: "",
        },
        phoneNumber: "",
        isLoggedIn: false,
        token: "",
    },
};

export const UserSlice = createSlice({
    name: "users",
    initialState,
    reducers: {
        addPhoneNumberToUser: (state, actions: PayloadAction<FieldValues>) => {
            Object.assign(state.user, actions.payload);
        },
        addTokenToUser: (state, actions: PayloadAction<AuthenticatedUser>) => {
            Object.assign(state.user, actions.payload);
        },
    },
});

export const { addPhoneNumberToUser, addTokenToUser } = UserSlice.actions;
export default UserSlice.reducer;
