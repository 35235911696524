import { PayloadAction, createSlice } from "@reduxjs/toolkit";
// import { LeadsData } from "../../components/table/LeadsTable";

interface ILeadSlice {
    paginationNumber: number;
    // paginateLeadData: LeadsData[];
}

const initialState: ILeadSlice = {
    paginationNumber: 1,
    // paginateLeadData: [],
};

export const LeadSlice = createSlice({
    name: "leads",
    initialState,
    reducers: {
        setPaginationNumber: (
            state,
            actions: PayloadAction<{ pageNumber: number }>
        ) => {
            state.paginationNumber = actions.payload.pageNumber;
        },
        setNextPagination: (state) => {
            state.paginationNumber += 1;
        },
        setPreviousPagination: (state) => {
            state.paginationNumber -= 1;
        },
        // setPaginationData: (state, actions: PayloadAction<LeadsData[]>) => {
        //     const newLeadData = actions.payload.map((lead) => lead);
        //     state.paginateLeadData = [...newLeadData];
        // },
    },
});

export const {
    // setPaginationData,
    setPaginationNumber,
    setNextPagination,
    setPreviousPagination,
} = LeadSlice.actions;
export default LeadSlice.reducer;
