import Grid from "@mui/material/Unstable_Grid2";
import CompaginBox from "../../../components/compagin-box/CompaginBox";
import { RiStackLine } from "react-icons/ri";
import { CardBox } from "../../../components/compagin-box/styles";
import CustomProgress from "../../../components/progress/CustomProgress";
import { Stack } from "@mui/material";

const TelegramDashboard = () => {
    return (
        <>
            {/* <CustomTab /> */}
            <Grid container spacing={2}>
                <Grid xs={3}>
                    <CompaginBox
                        Icon={RiStackLine}
                        label="تعداد کل شماره های موجود"
                        compaginNumber={5456}
                    />
                </Grid>
                <Grid xs={3}>
                    <CompaginBox
                        Icon={RiStackLine}
                        label="تعداد کل شماره های موجود"
                        compaginNumber={5456}
                    />
                </Grid>
                <Grid xs={3}>
                    <CompaginBox
                        Icon={RiStackLine}
                        label="تعداد کل شماره های موجود"
                        compaginNumber={5456}
                    />
                </Grid>
                <Grid xs={3}>
                    <CompaginBox
                        Icon={RiStackLine}
                        label="تعداد کل شماره های موجود"
                        compaginNumber={5456}
                    />
                </Grid>
            </Grid>

            <Grid sx={{ margin: "30px 0" }} container>
                <Grid xs={6}>
                    <CardBox>
                        <Stack sx={{padding: '18px 120px 18px 18px'}} gap="20px">
                            <CustomProgress />
                            <CustomProgress />
                            <CustomProgress />
                            <CustomProgress />
                        </Stack>
                    </CardBox>
                </Grid>
                <Grid xs={6}></Grid>
            </Grid>
        </>
    );
};

export default TelegramDashboard;
