import {
    Backdrop,
    Box,
    Button,
    Card,
    Fade,
    Modal,
    Typography,
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import { closeModal } from "../../redux/features/modalSlice";
import { Stack } from "@mui/system";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { LeadRequestProps } from "../lead-request/LeadRequest";
import moment from "moment-jalaali";

const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
};

const RequestLeadModal: React.FC<LeadRequestProps> = ({
    id,
    createdAt,
    description,
}) => {
    const dispatch: any = useAppDispatch();
    const isOpen = useAppSelector((state) => state.modal.isOpen);

    return (
        <Modal
            open={isOpen}
            onClose={() => dispatch(closeModal())}
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
            slotProps={{
                backdrop: {
                    timeout: 500,
                },
            }}
        >
            <Fade in={isOpen}>
                <Card sx={style}>
                    <Stack sx={{ padding: "0 12px", gap: "20px" }}>
                        <Stack>
                            <Grid
                                container
                                sx={{
                                    backgroundColor: "#007E6F",
                                    padding: "20px 0",
                                    borderRadius: "10px",
                                }}
                            >
                                <Grid xs={6}>
                                    <Stack
                                        justifyContent="center"
                                        alignItems="center"
                                        direction="row"
                                        gap="8px"
                                    >
                                        <Typography
                                            sx={{
                                                color: "#ffffff",
                                                fontWeight: "300",
                                                fontSize: "12px",
                                            }}
                                        >
                                            تاریخ ایجاد درخواست:
                                        </Typography>

                                        <Typography sx={{ color: "#ffffff" }}>
                                            {moment(createdAt, "YYYY/MM/DD")
                                                .locale("fa")
                                                .format("YYYY/MM/DD")}
                                        </Typography>
                                    </Stack>
                                </Grid>

                                <Grid xs={6}>
                                    <Stack
                                        justifyContent="center"
                                        alignItems="center"
                                        direction="row"
                                        gap="8px"
                                    >
                                        <Typography
                                            sx={{
                                                color: "#ffffff",
                                                fontWeight: "300",
                                                fontSize: "12px",
                                            }}
                                        >
                                            ساعت ایجاد درخواست:
                                        </Typography>

                                        <Typography sx={{ color: "#ffffff" }}>
                                            {moment(createdAt, "HH:mm:ss")
                                                .locale("fa")
                                                .format("HH:mm:ss")}
                                        </Typography>
                                    </Stack>
                                </Grid>
                            </Grid>
                        </Stack>

                        <Stack gap="20px">
                            <Typography
                                sx={{
                                    color: "#007E6F",
                                    fontWeight: "300",
                                    fontSize: "12px",
                                }}
                            >
                                توضیحات
                            </Typography>

                            <Card
                                sx={{
                                    padding: "20px 10px",
                                    backgroundColor: "#F3F3F3",
                                }}
                            >
                                {description}
                            </Card>
                        </Stack>

                        <Stack alignItems="flex-end">
                            <Button
                                sx={{ width: "120px" }}
                                variant="contained"
                                color="primary"
                            >
                                پیگیری شد
                            </Button>
                        </Stack>
                    </Stack>
                </Card>
            </Fade>
        </Modal>
    );
};

export default RequestLeadModal;
