import { Route, Routes } from "react-router-dom";
import DashboardLayout from "./components/dashboard-layout/DashboardLayout";
import MainDashboard from "./pages/dashboard/MainDashboard";
import ManagePersons from "./pages/manage_persons/ManagePersons";
import LoadNumber from "./pages/load_number/LoadNumber";
import SmsCompagin from "./pages/sms-compagin/SmsCompagin";
import PhoneCompagin from "./pages/phone-campagin/PhoneCompagin";
import TelegramDashboard from "./pages/telegram/dashboard/TelegramDashboard";
import ChannelsManagment from "./pages/telegram/channels/ChannelsManagment";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import Login from "./pages/login/Login";
import LoginLayout from "./pages/login/LoginLayout";
import Otp from "./pages/login/Otp";
import PrivateRoute from "./components/private-route/PrivateRoute";
import LeadsDashboard from "./pages/leads/leads_dashboard/LeadsDashboard";
import Leads from "./pages/leads/Leads";
import CreateLead from "./pages/leads/create_lead/CreateLead";
import LeadsCategory from "./pages/leads/leads_category/LeadsCategory";
import Lead from "./pages/leads/Lead";

const queryClient = new QueryClient();

function App() {
    return (
        <QueryClientProvider client={ queryClient }>
            <Routes>
                <Route element={ <DashboardLayout /> }>
                    <Route element={ <PrivateRoute /> }>
                        <Route path="/" element={ <MainDashboard /> } />
                        {/* telegram compagin routes */ }
                        <Route
                            path="/telegram-dashboard"
                            element={ <TelegramDashboard /> }
                        />
                        <Route
                            path="/manage-channels"
                            element={ <ChannelsManagment /> }
                        />
                        {/* end telegram compagin routes */ }

                        {/* leads routes */ }
                        <Route path="/leads" element={ <Leads /> } />
                        <Route path="/leads/:leadId" element={ <Lead /> } />
                        <Route
                            path="/leads-dashboard"
                            element={ <LeadsDashboard /> }
                        />
                        <Route path="/create-lead" element={ <CreateLead /> } />
                        <Route
                            path="/leads-category"
                            element={ <LeadsCategory /> }
                        />
                        {/* end leads routes */ }
                    </Route>

                    <Route path="/manage-persons" element={ <ManagePersons /> } />
                    <Route path="/load-number" element={ <LoadNumber /> } />
                    <Route path="/sms-compagin" element={ <SmsCompagin /> } />
                    <Route path="/phone-compagin" element={ <PhoneCompagin /> } />
                </Route>

                {/* login route */ }
                <Route element={ <LoginLayout /> }>
                    <Route path="/login" element={ <Login /> } />
                    <Route path="/login/otp" element={ <Otp /> } />
                </Route>
                {/* end login route */ }
            </Routes>
            {/* <ReactQueryDevtools initialIsOpen={false} /> */ }
        </QueryClientProvider>
    );
}

export default App;
