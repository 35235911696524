import "./index.css";

// import fonts]
import "./fonts/IRANYekanXVF.woff";
import "./fonts/IRANYekanXVF.woff2";
import { CookiesProvider } from "react-cookie";
import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material";
import { Provider } from "react-redux";
import { store } from "./redux/store";

const theme = createTheme({
    typography: {
        fontFamily: "yekan",
    },
    palette: {
        primary: {
            main: "#007e6f",
        },
        secondary: {
            main: "#00293f",
            light: "#98DCFF",
        },
        info: {
            main: "#007BBB",
        },
        error: {
            main: "#ff3636",
        },
    },
});

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
);
root.render(
    <React.StrictMode>
        <Provider store={store}>
            <ThemeProvider theme={theme}>
                <BrowserRouter>
                    <CookiesProvider>
                        <App />
                    </CookiesProvider>
                </BrowserRouter>
            </ThemeProvider>
        </Provider>
    </React.StrictMode>
);
