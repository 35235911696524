import { Button, Grid, Stack } from "@mui/material";
import CustomTable from "../../components/table/CustomTable";
import CustomPagination from "../../components/pagination/CustomPagination";
import { ButtonContainer } from "./styles";
import { SmsCompaginHeaderData, SmsCompaginTableData } from "../../tableData";

const SmsCompagin = () => {
    return (
        <Grid container justifyContent="center">
            <Grid xs={8}>
                <Stack gap="20px">
                    <ButtonContainer>
                        <Button
                            sx={{ width: "200px" }}
                            variant="contained"
                            color="primary"
                        >
                            ایجاد کمپین پیامکی
                        </Button>
                    </ButtonContainer>
                    <CustomTable
                        tableHeaderData={SmsCompaginHeaderData}
                        tableBodyData={SmsCompaginTableData}
                    />
                    <CustomPagination />
                </Stack>
            </Grid>
        </Grid>
    );
};

export default SmsCompagin;
