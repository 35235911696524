import { styled } from "@mui/system";
import { DailyReportTitle } from "../daily-report/styles";
import { Card } from "@mui/material";

export const SingleLoadPhoneTitle = styled(DailyReportTitle)(`
    color: #007e6f;
`);

export const FormContainer = styled(Card)(`
     box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
     padding: 25px 50px;
     background-color: #ffffff;
     border-radius: 15px;
`);
