import { Outlet } from "react-router-dom";
import { SideBarLayout, MainLayout } from "./styles";
import CustomSidebar from "../sidebar/CustomSidebar";
import Grid from "@mui/material/Unstable_Grid2";
import { routes } from "../../routes";
import { Stack, Typography } from "@mui/material";
import DropdownMenu from "../dropdown-menu/DropdownMenu";

import { CiUser, CiBellOn } from "react-icons/ci";

const DashboardLayout = () => {
    return (
        <>
            {/* top layout */}
            <Grid container bgcolor="#00293f" alignItems="center">
                <Grid xs={6}>
                    <Stack sx={{ padding: "1.5rem" }}>
                        <Typography color="#ffffff">
                            پیشگامان توسعه تجارت فردا
                        </Typography>
                    </Stack>
                </Grid>
                <Grid xs={6}>
                    <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="flex-end"
                    >
                        <DropdownMenu
                            tooltipLabel="اطلاعیه ها"
                            DropdownIcon={CiBellOn}
                        />
                        <DropdownMenu
                            tooltipLabel="حساب کاربری"
                            DropdownIcon={CiUser}
                        />
                    </Stack>
                </Grid>
            </Grid>
            {/* end top layout */}
            <Grid sx={{ padding: '20px' }} container>
                {/* main Layout */}

                {/* sidebar */}
                <Grid xs={2}>
                    <SideBarLayout>
                        <CustomSidebar routes={routes} />
                    </SideBarLayout>
                </Grid>
                {/* end sidebar */}
                <Grid xs={10} sx={{ paddingRight: '1rem' }}>
                    <MainLayout>
                        <Outlet />
                    </MainLayout>
                </Grid>
                {/* end main Layout */}
            </Grid>
        </>
    );
};

export default DashboardLayout;
