import { useQuery, useSuspenseQuery } from "@tanstack/react-query";
import axios from "../axiosInstance";
import useLocalStorage from "./useLocalStorage";
import { AuthenticatedUser } from "../redux/features/userSlice";
import { IRequestStatus } from "../utils/IRequestStatus";

interface QueryKey {
    page: number;
    limit: number;
}

interface UseLeadsQueryProps {
    key: QueryKey;
}

const useLeadsQuery = (leadKeys: UseLeadsQueryProps) => {
    const [value, updateValue] = useLocalStorage<AuthenticatedUser>("token");

    const getLeads = async (queryKeys: (string | UseLeadsQueryProps)[]) => {
        const { key } = queryKeys[1] as UseLeadsQueryProps;
        const { page, limit } = key;
        const queryParams: QueryKey = { page: page, limit: limit };

        const { data } = await axios.get<IRequestStatus>('/leads', {
            params: queryParams,
            headers: {
                Authorization: `Bearer ${ value?.token }`,
            },
        });
        return data;
    };

    const { data: leadsData } = useQuery({
        queryKey: ["leads", leadKeys],
        queryFn: async ({ queryKey }) => getLeads(queryKey),
        select: (result) => {
            return result?.data;
        }
    });

    return { leadsData };
}

export default useLeadsQuery;