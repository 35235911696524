import {
    Table,
    TableContainer,
    TableRow,
    TableBody,
    Stack,
    Button,
    TableHead,
} from "@mui/material";
import { CustomTableCell } from "./styles";
import { Paper } from "@mui/material";
import { IconType } from "react-icons";

export interface Actions {
    actionName: string;
    actionColor: string;
    ActionIcon: IconType;
}

interface CustomTableProps {
    tableHeaderData: string[];
    tableBodyData: any[];
    tableActions?: Actions[];
}

const CustomTable: React.FC<CustomTableProps> = ({
    tableBodyData,
    tableHeaderData,
    tableActions,
}) => {

    return (
        <TableContainer
            component={Paper}
            sx={{
                color: "transparent",
                padding: 0,
                boxShadow: "none",
                border: "1px solid #e9e9e9",
            }}
        >
            <Table aria-label="simple table">
                <TableHead sx={{ backgroundColor: "#fff" }}>
                    <TableRow>
                        {tableHeaderData?.map((data) => (
                            <CustomTableCell align="center">
                                {data}
                            </CustomTableCell>
                        ))}
                    </TableRow>
                </TableHead>
                {/* <TableBody>
                    {tableBodyData.map((res) => (
                        <TableRow
                            key={res.id}
                            sx={{
                                "&:last-child td, &:last-child th": {
                                    border: 0,
                                },
                            }}
                        >
                            {res.data.map((data: any) => (
                                <CustomTableCell
                                    sx={{
                                        fontSize: "1rem",
                                        padding: "1rem 0",
                                    }}
                                    align="center"
                                >
                                    {data}
                                </CustomTableCell>
                            ))}
                        </TableRow>
                    ))}
                </TableBody> */}
                <TableBody>
                    
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default CustomTable;
