import { FieldValues, SubmitHandler, useForm } from "react-hook-form";
import Input from "../input/Input";
import { FormContainer, SingleLoadPhoneTitle } from "./styles";
import { Button, Stack } from "@mui/material";
import { AiOutlineUserAdd } from "react-icons/ai";

const SingleLoadPhoneForm = () => {
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const onSubmit: SubmitHandler<FieldValues> = (data) => {
        console.log(data);
    };

    return (
        <FormContainer>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Stack gap="35px">
                    <Stack
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        gap="7px"
                    >
                        <AiOutlineUserAdd color="#007e6f" size={25} />

                        <SingleLoadPhoneTitle>
                            بارگزاری فردی شماره
                        </SingleLoadPhoneTitle>
                    </Stack>

                    <Stack gap="25px">
                        <Input
                            label="نام و نام خانوادگی"
                            id="name"
                            register={register}
                            errors={errors}
                        />
                        <Input
                            label="شماره موبایل"
                            id="phoneNumber"
                            register={register}
                            errors={errors}
                        />
                        <Input
                            label="نام کمپین"
                            id="CompaginName"
                            register={register}
                            errors={errors}
                        />

                        <Button
                            type="submit"
                            size="large"
                            variant="outlined"
                            color="primary"
                        >
                            ثبت شماره
                        </Button>
                    </Stack>

                    <Stack alignItems="center">
                        <span
                            style={{
                                fontSize: "13px",
                                color: "#007e6f",
                                fontWeight: "500",
                            }}
                        >
                            رفتن به بانک شماره ها
                        </span>
                    </Stack>
                </Stack>
            </form>
        </FormContainer>
    );
};

export default SingleLoadPhoneForm;
