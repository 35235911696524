import { Navigate, Outlet } from "react-router";
import { useCookies } from 'react-cookie';
import { AuthenticatedUser } from "../../redux/features/userSlice";
import useLocalStorage from "../../hooks/useLocalStorage";

const PrivateRoute = () => {

    const [value, updateValue] = useLocalStorage<AuthenticatedUser>("token");
    // const [cookies] = useCookies<any>(['accToken']);
    // const isLoggedIn = useAppSelector(state => state.user.user.isLoggedIn);

    return (
        <>
            { value?.token ? <Outlet /> : <Navigate to="/login" /> }
        </>
    );
};

export default PrivateRoute;
