import Grid from "@mui/material/Unstable_Grid2";
import CustomTable from "../../components/table/CustomTable";
import { Stack } from "@mui/material";
import CustomPagination from "../../components/pagination/CustomPagination";
import { ManagePersonTableData, ManagePersontableHeaderData } from "../../tableData";

const ManagePersons = () => {
    return (
        <Grid container>
            <Grid xs={12}>
                <Stack gap="20px">
                    <CustomTable tableHeaderData={ManagePersontableHeaderData} tableBodyData={ManagePersonTableData} />
                    <CustomPagination />
                </Stack>
            </Grid>
        </Grid>
    );
};

export default ManagePersons;
