import { Button, Stack } from "@mui/material";
import { QueryErrorResetBoundary, useQuery } from "@tanstack/react-query";
import CustomPagination from "../../components/pagination/CustomPagination";
import { useAppSelector } from "../../redux/store";
import { useAppDispatch } from "../../redux/store";
import { setPaginationNumber } from "../../redux/features/leadSlice";
import React, { lazy, useCallback, useEffect } from "react";
import CustomLoader from "../../components/loader/CustomLoader";
import useLocalStorage from "../../hooks/useLocalStorage";
import { AuthenticatedUser } from "../../redux/features/userSlice";
import { ErrorBoundary } from "react-error-boundary";
import useLeadsQuery from "../../hooks/useLeadsQuery";

const LeadsTable = lazy(() => import("../../components/table/LeadsTable"));

const Leads = () => {
    const [value, updateValue] = useLocalStorage<AuthenticatedUser>("token");
    const dispatch: any = useAppDispatch();

    const paginationNumber = useAppSelector(
        (state) => state.lead.paginationNumber
    );

    const { leadsData } = useLeadsQuery({
        key: {
            page: paginationNumber,
            limit: 7
        }
    });

    const handleChange = useCallback(
        (event: React.ChangeEvent<unknown>, value: number) => {
            dispatch(setPaginationNumber({ pageNumber: value }));
        },
        []
    );

    return (
        <Stack gap="20px">
            <QueryErrorResetBoundary>
                { ({ reset }) => (
                    <ErrorBoundary
                        onReset={ reset }
                        fallbackRender={ ({ error, resetErrorBoundary }) => (
                            <>
                                <div>یه بار دیگه تلاش کن.</div>
                                <Button variant="contained" color="info" onClick={ () => resetErrorBoundary() }>
                                    تلاش دوباره
                                </Button>
                            </>
                        ) }
                    >
                        <React.Suspense fallback={ <CustomLoader /> }>
                            <LeadsTable leads={ leadsData?.result } />
                        </React.Suspense>
                    </ErrorBoundary>
                ) }
            </QueryErrorResetBoundary>

            <CustomPagination
                onChange={ handleChange }
                count={ Math.ceil(leadsData?.numberOfDocuments as number / 10) }
            />
        </Stack>
    );
};

export default Leads;
