import { Stack, Typography } from "@mui/material";
import { BorderLinearProgress } from "./styles";

interface CustomProgressProps {
    themeType?: string;
    value?: number;
    label?: string;
}

const CustomProgress: React.FC<CustomProgressProps> = ({
    themeType,
    value,
    label,
}) => {
    return (
        <Stack gap="10px">
            <Stack direction="row" justifyContent="space-between">
                <Typography
                    sx={{
                        fontSize: "16px",
                        fontWeight: "600",
                        color: (theme) => theme.palette.info.main,
                    }}
                    variant="body2"
                    color="text.secondary"
                >
                    {label}
                </Typography>
                <Typography
                    sx={{
                        fontSize: "16px",
                        fontWeight: "600",
                        color: (theme) => theme.palette.info.main,
                    }}
                    variant="body2"
                    color="text.secondary"
                >
                    {value + "%"}
                </Typography>
            </Stack>
            <BorderLinearProgress
                color="info"
                variant="determinate"
                value={value}
            />
        </Stack>
    );
};

export default CustomProgress;
