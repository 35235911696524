import { Button, Grid, Stack } from "@mui/material";
import CustomTable from "../../components/table/CustomTable";
import CustomPagination from "../../components/pagination/CustomPagination";
import { ButtonContainer } from "../sms-compagin/styles";
import {
    PhoneCompaginHeaderData,
    PhoneCompaginTableData,
} from "../../tableData";

const PhoneCompagin = () => {
    return (
        <Grid container justifyContent="center">
            <Grid xs={8}>
                <Stack gap="20px">
                    <ButtonContainer>
                        <Button
                            sx={{ width: "200px" }}
                            variant="contained"
                            color="primary"
                        >
                            ایجاد کمپین تماس
                        </Button>
                    </ButtonContainer>
                    <CustomTable
                        tableHeaderData={PhoneCompaginHeaderData}
                        tableBodyData={PhoneCompaginTableData}
                    />
                    <CustomPagination />
                </Stack>
            </Grid>
        </Grid>
    );
};

export default PhoneCompagin;
