// import { Input, inputClasses } from "@mui/base/Input";
import { TextField, TextFieldProps } from "@mui/material";
import { styled } from "@mui/system";

export const InputLabel = styled("label")({
    display: "block",
    fontWeight: 500,
    fontSize: "16px",
    color: "#00293f",
    marginBottom: "10px",
});

export const InputContainer = styled("div")({
    width: "100%",
});

export const TextInput = styled(TextField)<TextFieldProps>({
    width: "100%",
    [`& fieldset`]: {
        borderColor: '#004163',
        borderRadius: "5px",
    },
    "& .MuiOutlinedInput-root": {
        "& .MuiInputBase-input": {
            padding: "10px 14px",
        },
        "&.Mui-focused fieldset": {
            borderWidth: "1px",
            borderColor: "rgba(0, 0, 0, 0.23)",
        },
    },
});
