import styled from "@emotion/styled";
import { TableCell, TableHead, TableRow } from "@mui/material";

export const CustomTableRow = styled(TableRow)<{ isActive?: string }>`
  position: relative;

  &::after {
    content: "";
    width: 5px;
    height: 50px;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    position: absolute;
    top: 50%;
    right: -2px;
    transform: translateY(-50%);
    background-color: ${(props) =>
      props.isActive === "active"
        ? "#007e6f"
        : props.color === "nonActive"
        ? "#ff3636"
        : "#ffffff"};
    z-index: 1;
  }
`;

export const CustomTableHead = styled(TableHead)``;

export const CustomTableCell = styled(TableCell)(() => ({}));
