import { IconType } from "react-icons";
import { IoCopyOutline } from "react-icons/io5";

interface Actions {
    actionName: string;
    actionColor: string;
    ActionIcon: IconType;
}

export const SmsDashboardtableHeaderData = [
    "کمپین",
    "پیام های ارسال شده",
    "پیام های دریافت شده",
    "شماره ها",
];
export const SmsDashboardTableData = [
    ["کمپین شماره 3 آذر", 2345, 2147, 3456],
    ["کمپین شماره 3 آذر", 2345, 2147, 3456],
    ["کمپین شماره 3 آذر", 2345, 2147, 3456],
    ["کمپین شماره 3 آذر", 2345, 2147, 3456],
    ["کمپین شماره 3 آذر", 2345, 2147, 3456],
    ["کمپین شماره 3 آذر", 2345, 2147, 3456],
    ["کمپین شماره 3 آذر", 2345, 2147, 3456],
    ["کمپین شماره 3 آذر", 2345, 2147, 3456],
];
export const PhoneDashboardtableHeaderData = [
    "کمپین",
    "پاسخ داده شده",
    "پاسخ داده نشده",
    "شماره ها",
];
export const PhoneDashboardTableData = [
    ["کمپین شماره 1 آذر", 2345, 2147, 3456],
    ["کمپین شماره 6 آذر", 2345, 2147, 3456],
    ["کمپین شماره 8 آذر", 2345, 2147, 3456],
    ["کمپین شماره 10 آذر", 2345, 2147, 3456],
    ["کمپین شماره 16 آذر", 2345, 2147, 3456],
    ["کمپین شماره 20 آذر", 2345, 2147, 3456],
    ["کمپین شماره 23 آذر", 2345, 2147, 3456],
    ["کمپین شماره 30 آذر", 2345, 2147, 3456],
];
export const ManagePersontableHeaderData = [
    "نام و نام خانوادگی",
    "شماره موبایل",
    "شهر",
    "کمپین",
];
export const ManagePersonTableData = [
    ["حسین امامی", '0' + 912345678, 'تهران', "کمپین شماره 3 آذر"],
    ["حسین امامی", '0' + 912345678, 'تهران', "کمپین شماره 3 آذر"],
    ["حسین امامی", '0' + 912345678, 'تهران', "کمپین شماره 3 آذر"],
    ["حسین امامی", '0' + 912345678, 'تهران', "کمپین شماره 3 آذر"],
    ["حسین امامی", '0' + 912345678, 'تهران', "کمپین شماره 3 آذر"],
    ["حسین امامی", '0' + 912345678, 'تهران', "کمپین شماره 3 آذر"],
    ["حسین امامی", '0' + 912345678, 'تهران', "کمپین شماره 3 آذر"],
    ["حسین امامی", '0' + 912345678, 'تهران', "کمپین شماره 3 آذر"],
];
export const LoadNumberHeaderData = [
    'نام پوشه',
    'تعداد شماره ها',
    'تاریخ آپلود'
];
export const LoadNumberTableData = [
    ["پوشه شماره یکم", 2341, '1402/08/12'],
    ["پوشه شماره یکم", 2341, '1402/08/12'],
    ["پوشه شماره یکم", 2341, '1402/08/12'],
    ["پوشه شماره یکم", 2341, '1402/08/12'],
    ["پوشه شماره یکم", 2341, '1402/08/12'],
];
export const SmsCompaginHeaderData = [
    'نام کمپین',
    'تعداد شماره ها',
    'پیام های ارسال شده',
    'پیام های دریافت شده',
    'ارزیابی کمپین'
];
export const SmsCompaginTableData = [
    ["کمپین شماره 3 آذر", 2345, 2147, 104, 'موفق'],
    ["کمپین شماره 3 آذر", 2345, 2147, 104, 'موفق'],
    ["کمپین شماره 3 آذر", 2345, 2147, 104, 'موفق'],
    ["کمپین شماره 3 آذر", 2345, 2147, 104, 'موفق'],
    ["کمپین شماره 3 آذر", 2345, 2147, 104, 'موفق'],
    ["کمپین شماره 3 آذر", 2345, 2147, 104, 'موفق'],
];
export const PhoneCompaginHeaderData = [
    'نام کمپین',
    'تعداد شماره ها',
    'پاسخ داده شده',
    'پاسخ داده نشده',
    'ارزیابی کمپین'
];
export const PhoneCompaginTableData = [
    ["کمپین شماره 3 آذر", 2345, 2147, 104, 'موفق'],
    ["کمپین شماره 3 آذر", 2345, 2147, 104, 'موفق'],
    ["کمپین شماره 3 آذر", 2345, 2147, 104, 'موفق'],
    ["کمپین شماره 3 آذر", 2345, 2147, 104, 'موفق'],
    ["کمپین شماره 3 آذر", 2345, 2147, 104, 'موفق'],
    ["کمپین شماره 3 آذر", 2345, 2147, 104, 'موفق'],
];


// operations array

export const telegramCompaginactions: Actions[] = [
    {
        actionName: 'copy',
        actionColor: 'info',
        ActionIcon: IoCopyOutline,
    }
];

export const TelegramCompaginHeaderData = [
    'نام عمومی کانال',
    'آیدی کانال',
    'تاریخ ایجاد',
    'تعداد ورودی',
    'عملیات'
];
export const TelegramCompaginTableData = [
    ["همایون ارشادی", '0' + 91212345678, 'کمپین شماره 1 آذر ماه', 'کارشناسی', telegramCompaginactions],
    ["همایون ارشادی", '0' + 91212345678, 'کمپین شماره 1 آذر ماه', 'کارشناسی', telegramCompaginactions],
    ["همایون ارشادی", '0' + 91212345678, 'کمپین شماره 1 آذر ماه', 'کارشناسی', telegramCompaginactions],
    ["همایون ارشادی", '0' + 91212345678, 'کمپین شماره 1 آذر ماه', 'کارشناسی', telegramCompaginactions],
    ["همایون ارشادی", '0' + 91212345678, 'کمپین شماره 1 آذر ماه', 'کارشناسی', telegramCompaginactions],
    ["همایون ارشادی", '0' + 91212345678, 'کمپین شماره 1 آذر ماه', 'کارشناسی', telegramCompaginactions],
];
