import { FieldValues, SubmitHandler, useForm } from "react-hook-form";
import Input from "../input/Input";
import { Button, Stack } from "@mui/material";
import { HiOutlineUserGroup } from "react-icons/hi";
import { GroupFormContainer, GroupLoadFormTitle } from "./styles";
import CustomDropzone from "../dropzone/CustomDropzone";

const GroupLoadPhoneForm = () => {
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const onSubmit: SubmitHandler<FieldValues> = (data) => {
        console.log(data);
    };

    return (
        <GroupFormContainer>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Stack gap="25px" alignItems="center">
                    <Stack
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        gap="7px"
                    >
                        <HiOutlineUserGroup color="#007e6f" size={25} />

                        <GroupLoadFormTitle>
                            بارگزاری گروهی شماره
                        </GroupLoadFormTitle>
                    </Stack>

                    <Stack gap="25px">
                        <Input
                            label="نام فایل"
                            id="fileName"
                            register={register}
                            errors={errors}
                        />

                        {/* <CustomDropzone
                            id="upload_phones"
                            register={register}
                        /> */}

                        <Button
                            type="submit"
                            size="large"
                            variant="contained"
                            color="primary"
                        >
                            ثبت شماره
                        </Button>
                    </Stack>
                </Stack>
            </form>
        </GroupFormContainer>
    );
};

export default GroupLoadPhoneForm;
