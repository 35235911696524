import {
    Button,
    Card,
    Container,
    Paper,
    Stack,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TableRow,
} from "@mui/material";
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";
import WrapperLabel from "../../../components/wrapper-label/WrapperLabel";
import Input from "../../../components/input/Input";
import Grid from "@mui/material/Unstable_Grid2";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { CustomTableCell } from "../../../components/table/styles";
import { IoCopyOutline } from "react-icons/io5";

const ChannelsManagment = () => {
    const getChannels = async () => {
        const { data } = await axios.get(
            "https://api.pttfco.com/api/v1/channels/"
        );
        return data;
    };

    const {
        data: channelData,
        isLoading,
        isFetching,
    } = useQuery({
        queryKey: ["channels"],
        queryFn: async () => getChannels(),
    });

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const onSubmit: SubmitHandler<FieldValues> = async (data) => {
        await axios.post("https://api.pttfco.com/api/v1/channels/create", data);
    };

    if (isLoading) {
        return (
            <div>
                <span>is loading</span>
            </div>
        );
    }

    if (isFetching) {
        return (
            <div>
                <span>is fetching</span>
            </div>
        );
    }

    return (
        <Stack gap="20px">
            <Card>
                <Container maxWidth={false}>
                    <Stack
                        sx={{ padding: "35px 0", position: "relative" }}
                        gap="18px"
                    >
                        <WrapperLabel className="dddd">
                            افزودن کانال
                        </WrapperLabel>
                        <form
                            style={{ marginTop: "70px 0" }}
                            onSubmit={handleSubmit(onSubmit)}
                        >
                            <Grid container spacing={2} alignItems="flex-end">
                                <Grid xs={5}>
                                    <Input
                                        label="نام عمومی کانال"
                                        id="channelPublicName"
                                        register={register}
                                        errors={errors}
                                    />
                                </Grid>
                                <Grid xs={5}>
                                    <Input
                                        label="آیدی کانال"
                                        id="channelId"
                                        register={register}
                                        errors={errors}
                                    />
                                </Grid>
                                <Grid xs={2}>
                                    <Button
                                        sx={{ margin: "0 0 2px 0" }}
                                        type="submit"
                                        size="large"
                                        variant="contained"
                                        color="primary"
                                    >
                                        افزودن
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                    </Stack>
                </Container>
            </Card>
            
            <TableContainer
                component={Paper}
                sx={{
                    color: "transparent",
                    padding: 0,
                    boxShadow: "none",
                    border: "1px solid #e9e9e9",
                }}
            >
                <Table aria-label="simple table">
                    <TableHead sx={{ backgroundColor: "#fff" }}>
                        <TableRow>
                            <CustomTableCell align="center">
                                نام عمومی کانال
                            </CustomTableCell>
                            <CustomTableCell align="center">
                                آیدی کانال
                            </CustomTableCell>
                            <CustomTableCell align="center">
                                تاریخ ایجاد
                            </CustomTableCell>
                            <CustomTableCell align="center">
                                تعداد ورودی
                            </CustomTableCell>
                            <CustomTableCell align="center">
                                عملیات
                            </CustomTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {channelData.data.map((res: any) => (
                            <TableRow>
                                <CustomTableCell align="center">
                                    {res.channelPublicName}
                                </CustomTableCell>
                                <CustomTableCell align="center">
                                    {res.channelId}
                                </CustomTableCell>
                                <CustomTableCell align="center">
                                    {res.createdAt}
                                </CustomTableCell>
                                <CustomTableCell align="center">
                                    6
                                </CustomTableCell>
                                <CustomTableCell align="center">
                                    <Stack
                                        direction="row"
                                        justifyContent="center"
                                        gap="10px"
                                    >
                                        <Button
                                            sx={{
                                                minWidth: "40px",
                                                padding: "8px 10px",
                                            }}
                                            variant="contained"
                                            color="info"
                                        >
                                            <IoCopyOutline fontSize="20px" />
                                        </Button>
                                    </Stack>
                                </CustomTableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Stack>
    );
};

export default ChannelsManagment;
